.Hero {
  background: var(--Very-Light-Gray);
  direction: rtl;
}

.hero__container__bg {
  background: url("../../images/bait_web.svg") no-repeat center;
  background-size: contain;
  background-color: var(--Very-Light-Gray); /* Add fallback background color */
  min-height: 422px; /* Change height to min-height */
  height: auto; /* Allow height to grow if needed */
  max-height: max-content;
  border-radius: 0 10px 0 10px;
  z-index: 1; /* Ensure element is positioned in stacking context */
  position: relative; /* Enable z-index */
}

.hero__container_stores {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.hero_button_stores {
  width: 100%;
}

.hero__container__title {
  margin: 1px 30px 0px;
  color: var(--Dark-Blue);
}

.hero__container__desc {
  margin: 24px 30px;
}

@media screen and (min-width: 1240px) {
  .hero__container {
    display: grid;
    grid-template-columns: 45% 55%;
  }

  .hero__container_stores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .hero__container div:nth-of-type(1) {
    order: 2;
  }

  .hero__container div:nth-of-type(2) {
    order: 1;
  }

  .hero__container__title {
    margin: 180px 80px 0px 160px;
    text-align: center;
  }

  .hero__container__desc {
    margin: 14px 80px 30px 160px;
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
  }

  .hero__container .Button {
    margin: 14px 20px 30px 100px;
    text-align: center;
  }

  .hero__container__bg {
    position: relative;
    z-index: 100;
    background: url("../../images/bait_web.svg");
    background-position: center;
    height: 810px;
    background-repeat: no-repeat;
    background-size: contain;
    max-height: max-content;
  }
}

.Footer{
  background: var(--Dark-Blue);
  text-align: center;
  padding-bottom: 40px;
}

.footer__container__logo{
  background: url("../../images/logoWhite.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  background-size: 150px;
  width: 150px;
  height: 100%;
}

.footer__container__socialMedia{
  display: flex;
  justify-content: center;
}

.footer__container__socialMedia a{
  padding: 0 9.5px;
  width: fit-content;
  transform: scale(1.2);
}

.footer__container__links{
  padding: 39px 0 20px 0;
}

.footer__container__links a{
  margin: 0 auto 17px auto;
  width: fit-content;
  display: block;
  text-decoration: none;
  color: var(--White);
  font-size: 14.3px;
  font-weight: 400;
}

.footer__container__elements p{
  font-size: 14.7px;
  padding-top: 24px;
}


.footer__container__buttonRights .Button, .footer__container__buttonRights p{
  color: #FFFFFF;
}

@media screen and (min-width: 1240px){
  .Footer{
    padding: 20px 160px;
  }

  .footer__container__elements{
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .footer__container__logo{
    background-position-y: 0px;
    height: 70px;
    background-position-x: left;
  }

  .footer__container__links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: left;
  }

  .footer__container__links a{
    margin: 0 120px 17px 0;
    font-weight: 300;
  }

  .footer__container__buttonRights{
    justify-self: end;
  }
  
  .footer__container__buttonRights .Button, .footer__container__buttonRights p{
    float: right;
    color: #FFFFFF;
  }

  .footer__container__socialMedia{
    float: left;
  }
}


.SecureHoot {
  color: #FFFF;
}

.SecureHoot:hover{
  color: lightblue;
}
.Article{
  display: block;
  position: relative;
  margin: 0px 0px 24px 0px;
  background-color: var(--White);
  border-radius: 6px;
  direction: ltr;
}

.article__cover{
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 6px 6px 0 0;
}

.article__data{
  padding: 20px 31px 41px 31px;
}

.article__data__author, .article__data__title, .article__data__excerpt{
  text-align: center;
}

.article__data__title{
  margin: 9px 0
  
}

.article__data__title:hover{
  color: var(--Lime-Green);
  margin: 9px 0
}

.article__data__excerpt{
  font-size: 12.6px;
  line-height: 1.51;
}

@media screen and (min-width: 1240px){
  .Article{
    margin: 10px 15px;
  }

  .article__data{
    padding: 10px 24px 20px 24px;
  }
}
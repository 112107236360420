.show {
  display: block;
  z-index: 5;
}

.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000003a;
  transition: opacity 0.2s ease;  
  z-index: 5;
}

.modal {
  width: calc(100% - 52px);
  position: absolute;
  padding: 12px;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  background-color: var(--White);
  border-radius: 4px;
  transform: translateY(86px);
  transition: transform 0.2s ease;
  box-shadow: 0px 0px 130px 80px rgba(248, 84, 95, 0.966);
  z-index: 5;
}

.modal__container__links a{
  margin: 20px auto 25px auto;
  width: fit-content;
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  color: var(--Dark-Blue);
}
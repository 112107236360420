@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.block-overflow {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

:root {
  --Dark-Blue: #f8545f;
  --Lime-Green: #f8545f;
  --Bright-Cyan: #f8545f;

  --Grayish-Blue: hsl(233, 8%, 62%);
  --Light-Grayish-Blue: hsl(220, 16%, 96%);
  --Very-Light-Gray: hsl(0, 0%, 98%);
  --White: hsl(0, 0%, 100%);
}

h1,
h2,
h3,
h4,
h5,
p,
a,
li,
span,
label {
  font-family: "Public Sans", sans-serif;
  text-align: center;
}

h1 {
  letter-spacing: -0.021em;
  font-size: 2.5rem;
  color: var(--Dark-Blue);
  font-weight: 400;
}

h2 {
  letter-spacing: -0.02em;
  font-size: 2rem;
  color: var(--Dark-Blue);
  font-weight: 400;
}
h3 {
  letter-spacing: -0.02em;
  font-size: 1.25rem;
  color: var(--Dark-Blue);
  font-weight: 400;
}

h4 {
  letter-spacing: -0.02em;
  font-size: 16px;
  color: var(--Dark-Blue);
  font-weight: 400;
}

p {
  color: var(--Grayish-Blue);
  font-size: 14.4px;
  line-height: 1.815;
  font-weight: 400;
}

span {
  color: var(--Grayish-Blue);
  font-size: 9.5px;
}

a {
  color: var(--Grayish-Blue);
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

li {
  list-style: none;
  color: var(--White);
}

@media screen and (min-width: 1240px) {
  h1 {
    font-size: 3.5rem;
    color: black;
  }
  h2 {
    font-size: 2.5rem;
    color: black;
  }
  h3 {
    font-size: 1.5rem;
    color: black;
  }
  h4 {
    font-size: 16.8px;
    color: black;
  }
  p {
    font-size: 1.1rem;
  }
}

.containerproperties {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  height: auto; /* Added to make the container responsive */
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.iframes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  margin-top: 50px;
}

.property-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.property-info p {
  margin-right: 10px;
}

.ImagesInCarousel,
.Carousel {
  width: 80%;
  height: 80vh;
  object-fit: fill;
}

.cardproperties {
  max-width: 500px;
  width: 100%;
  background-color: var(--White);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Added to make the card responsive */
  flex-wrap: wrap;
}

.cardproperties h2 {
  color: var(--Dark-Blue);
  font-weight: bold;
}

.left {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
}

.right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: auto;
  flex-wrap: wrap;
  margin-right: 40px;
}

.right .desc {
  text-align: center;
  align-self: center;
  margin-top: 30px;
}

.right p {
  text-align: right;
}

.right h2 {
  color: var(--Dark-Blue);
  text-align: center;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .ImagesInCarousel,
  .Carousel {
    width: 100%;
    height: 50vh;
    object-fit: fill;
  }
  .containerproperties {
    height: 100vh;
  }
  .cardproperties {
    width: 100%;
    height: auto;
  }
  .left,
  .right {
    width: 100%;
    margin-right: 0;
  }
  .right h2,
  .right p {
    text-align: center;
    align-self: center;
  }
  .right h2 {
    margin-top: 50px;
  }
  .inputBox input {
    width: 100%;
  }
  .card .submit {
    width: 100%;
  }

  .iframes {
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 2rem auto 2rem auto;
  }

  .googlemaps {
    width: 900px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  height: 100vh; /* Added to make the container responsive */
  margin-bottom: 5rem;
}

.toast {
  z-index: 6;
}

.Terms {
  width: 100%;
  background-color: var(--White);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto; /* Added to make the card responsive */
}

#baitcolor {
  color: var(--Dark-Blue);
}

.Terms p {
  text-align: left;
}

.Terms li {
  color: var(--Grayish-Blue);
}

.Terms #ara {
  text-align: right;
  direction: rtl;
}

.card {
  max-width: 500px;
  width: 100%;
  background-color: var(--White);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto; /* Added to make the card responsive */
}

.card textarea {
  width: 100%;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  color: var(--Dark-Blue);
  border: none;
  border-bottom: 2px solid var(--Grayish-Blue);
  outline: none;
  background: transparent;
  resize: none;
  height: 100px;
  margin-top: 1rem;
}

.card h2 {
  color: var(--Dark-Blue);
  font-weight: bold;
}

.inputBox {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.inputBox label {
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  color: var(--Dark-Blue);
  pointer-events: none;
  transition: 0.5s;
  margin-top: 1rem;
}

.inputBox input {
  width: 100%;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  color: var(--Dark-Blue);
  border: none;
  border-bottom: 2px solid var(--Grayish-Blue);
  outline: none;
  background: transparent;
}

.mobile {
  display: none;
}

.submit {
  width: 100%;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  color: var(--White);
  border: none;
  border-radius: 10px;
  outline: none;
  background: var(--Dark-Blue);
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 2rem;
}

.virtual {
  max-width: 500px;
}

@media screen and (max-width: 768px) {
  .container {
    height: 100vh; /* Changed to make the container responsive */
  }
  .card {
    width: 100vw;
    height: auto; /* Changed to make the card responsive */
  }

  .mobile {
    display: block;
  }

  .Terms {
    width: 100%;
    background-color: var(--White);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto; /* Added to make the card responsive */
  }
}

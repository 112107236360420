.Articles{
  padding: 0px 24px 64px 24px;
  background: var(--Very-Light-Gray);
  direction: rtl;
}

.Articles__title{
  padding: 32px 0 32px 0;
  text-align: center;
}

@media screen and (min-width: 1240px){
  .Articles{
    padding: 56px 0px 84px;
    direction: rtl;
  }

  .Articles__container{
    margin: 0 160px;
  }

  .Articles__title{
    text-align: center;
    padding-bottom: 50px;
    color: var(--Dark-Blue);
  }
  
  .Articles__article{
    margin: 0 -15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}